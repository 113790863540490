import  { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import './Testimonials.css';

// import required modules
import { Pagination } from 'swiper/modules';

export default function Testimonials() {
  return (
    <section id='Testimonials'>
      <div id='heading'>Testimonials</div>
      <Swiper
        slidesPerView={3}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
        >
          <SwiperSlide>Excited to share my positive store experience! The inviting ambiance and organized layout made exploring ready-made clothes a breeze. Cleanliness and well-maintained displays added to the positive atmosphere.
Kudos to staff for their attentiveness and product knowledge. Their friendly demeanour made shopping enjoyable, showcasing commitment to customer satisfaction. 
For improvement, consider periodic inventory updates for staying on top of trends. Offering personalized experiences like styling services could enhance the customer journey.
On a scale of 1 to 10, I rate your store [X]. The welcoming environment, attentive staff, and quality merchandise contribute to this score. I'll return for future shopping and recommend your store to friends and family.
Look forward to your store's continued success-

Mr. H. Kayang
</SwiperSlide>
        <SwiperSlide>I'm the permanent customer of Selection Lovi's Shillong  ......very good quality of products..... and they really respect every customer...... I like it....fantastic -George Mukhim</SwiperSlide>
        <SwiperSlide>One stop shop for excellent quality readymade garments....5 stars more for customer service.....100% recommended....5 stars too less for such an exquisite store...bravo...keep it up!!! - Kenneth Sohtun</SwiperSlide>
        <SwiperSlide>Great Place -Arwot Lang Syngkon</SwiperSlide>
        <SwiperSlide>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem eveniet animi, sequi sed excepturi ad debitis rem doloremque, officia nihil praesentium fugiat?</SwiperSlide>
      </Swiper>
    </section>
  );
}
