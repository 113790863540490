import './Team.css'
import sanjay from './Sanjay.jpg'
import girdhari from './Girdhari.jpg'
function Team(){
    return(
        <section id='Team'>
            <h2 id='heading'>Team</h2>
            <div className='frame'>
            <div className='temp-team'>
                <img src={girdhari} alt="" className='potrait'/>
                <p className='name'>Girdharilal J Amarnani</p>
                <p className='designation'>Owner</p>
            </div>
            <div className='temp'>
                <img src={sanjay} alt=""  className='potrait'/>
                <p className='name'>Sanjay Amarnani</p>
                <p className='designation'>Owner</p>
            </div>
            </div>
        </section>
    );
}

export default Team