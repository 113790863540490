import './Contact.css'
import {FaLocationDot} from 'react-icons/fa6'
import {IoCallSharp} from 'react-icons/io5'
import React, { useRef } from 'react';
import emailjs from 'emailjs-com'

function Contact(){
    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
      emailjs.sendForm('service_hspwtqd', 'template_vk5ff1z', form.current, 'ROssJcC9metH7blb1')
      .then((result) => {
        alert("Thankyou for your feedback");
    }, (error) => {
        alert("Something went wrong :(");
    });
    };

    return(
        <section id='Contact'>
            <h2 id='heading'>Contact Us</h2>
            <div id='main--content'>
                <div id='map--and--contact'>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3598.8789633997435!2d91.87864607492878!3d25.5756933162671!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x37507e92727416bf%3A0x4053fcf4862d92fd!2sSELECTIONS%20LOVI&#39;S%20ARCADE!5e0!3m2!1sen!2sin!4v1698331021798!5m2!1sen!2sin" width="400" height="250" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    <br />
                    
                    <div id='address'>
                    <FaLocationDot className='contact--icon' size={25} />
                    <a href='https://maps.app.goo.gl/CcNbbPVwydq1P5396'>Selections, lovis Arcade, Opp. hotel Broadway,G S Road, Shillong-793001</a>
                    </div>
                    <div id='contact--number'>
                    <IoCallSharp className='contact--icon' size={25} />
                    <div>
                        <p className='inner-contact-details'>0364-2501342</p>
                        <p className='inner-contact-details'>+919863315378</p>
                    </div>
                    </div>
                </div>
                <div id='send--message'>
                    <p>Feel free to get in touch with us</p>
                    <br />
                    <form ref={form} onSubmit={sendEmail}>
                        <label className='field'>
                            Name
                        </label>
                        <br />
                            <input type="text"  name='name' id='name' placeholder='Full Name' />
                        <br />
                        <label className='field'>
                            Email
                        </label>
                        <br />
                            <input type="email" name="email" id="email" placeholder='Email address'/>
                        <br />
                        <label className='field'>
                            Message
                        </label>
                        <br />
                            <textarea name="message" id="message" cols="25" rows="1">How can we get better?</textarea>
                            <br />
                        <button type="submit">Submit</button>
                    </form>
                </div>
            </div>
        </section>
    );
}

export default  Contact;