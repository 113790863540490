import customer1 from './customer1.jpg'
import customer2 from './customer2.jpg'
import image1 from './pictures/image1.jpg'
import image2 from './pictures/image2.jpg'
import image3 from './pictures/image3.jpg'
import image4 from './pictures/image4.jpg'
import image5 from './pictures/image5.jpg'
import image6 from './pictures/image6.jpg'
import image7 from './pictures/image7.jpg'
import image8 from './pictures/image8.jpg'

export const images = [
    {
      original: customer1,
      thumbnail: customer1,
    },
    {
        original: customer2,
        thumbnail: customer2,
      },
      {
        original: image1,
        thumbnail: customer2,
      },
      {
        original: image2,
        thumbnail: customer2,
      },
      {
        original: image3,
        thumbnail: customer2,
      },
      {
        original: image4,
        thumbnail: customer2,
      },
      {
        original: image5,
        thumbnail: customer2,
      },
      {
        original: image6,
        thumbnail: customer2,
      },
      {
        original: image7,
        thumbnail: customer2,
      },
      {
        original: image8,
        thumbnail: customer2,
      }
  ];