import './Gallery.css'
import ImageGallery from 'react-image-gallery';
import {images} from './data'

function renderItem(item) {
    return (
      <div className='image-gallery-image'>
        <img src={item.original} alt={item.description} />
      </div>
    );
  }

function Gallery(){
    return(
            <section id='Gallery' className='gallery'>
                <h2 id='heading'>Gallery</h2>
                <ImageGallery
                    items={images}
                    showThumbnails={false}
                    showFullscreenButton={false}
                    showNav={false}
                    showPlayButton={false}
                    autoPlay={true}
                    slideInterval={2000}
                    renderItem={renderItem}
                />
            </section>
    );
}

export default Gallery