import './About.css'
import about from './pictures/about2.jpg'


function About(){
    return(
        <section  id='about'>
            <h2 id='heading'>About Us</h2>
            <p id='para'>With the goal of delivering the latest fashion trends to the people of Shillong, Selections is a ready-made garments showroom located in the heart of the Shillong city. We started in the year 2008 with a few hundred customers and here we are in 2023 with over 10,000+ customers. We have completed 14 successful years of service to our customers and look forward to serve the people of Shillong with the same dedication and sincerity.</p>
            <img src={about} alt="" id='image' />     
        </section>
    )
}

export default About;