import './Collection.css'
import man from './man.jpg'
import women from './women.jpg'
import boy from './boy.jpg'
import {AiOutlineMan} from 'react-icons/ai'
import {AiOutlineWoman} from 'react-icons/ai'
import {TbMoodBoy} from 'react-icons/tb'

function Collection(){
    return(
        <section id='collection'>
            <div id='heading'>What We Sell</div>   
            <div id='template'>
                <div className='temp'>
                <a href="/men">
                <img src={man} alt="" className='image' /><br />
                <div className='buticon'>
                <AiOutlineMan className='icon'/>
                <button className='tag'>Men</button>
                </div>
                </a>
                </div>
                <div className='temp'>
                <a href="/women">
                <img src={women} alt="" className='image' /><br />
                <div className='buticon'>
                <AiOutlineWoman className='icon'/>
                <button className='tag'>Women</button>
                </div>
                </a>
                </div>
                <div className='temp'>
                <a href="/kids">
                <img src={boy} alt="" className='image' /><br />
                <div className='buticon'>
                <TbMoodBoy className='icon'/>
                <button className='tag'>Boys & Babies</button>
                </div>
                </a>
                </div>
            </div>
        </section>
        );
}

export default Collection;