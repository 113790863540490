import './LandingPage.css'
import image from './image.png'
import logo from './Logo.png'
import Typewriter from "typewriter-effect";
import landingimage from './pictures/landingimage2.jpg'

function LandingPage(){
    return (
      <section id='LandingPage' className='LandingPage'>
        <div className='main-content'>
        <div className='content'>
            <div className='quote'>
            <Typewriter
              onInit={(typewriter) => {
                  typewriter
                      .typeString("Life isn’t perfect But your outfits can be.")
                      .pauseFor(5000)
                      .deleteAll()
                      .typeString("Life isn’t perfect But your outfits can be.")
                      .start();
              }}
              />
              </div>
            <p className='smallText'>Your one stop solution to all clothing needs</p>
            <div className='buttons'>
            <a href="#collection"><button className='Collections'>Our Collections</button></a>
            <a href="#Contact"><button className='Contact'>Contact Us</button></a>
            </div>
          </div>
          <div className='image'>
            <img src={landingimage} alt="" id='store'/>
          </div>
          </div>
          <div className='sub-content'>
            
            <div className='logo-repeat'>
            <img src={logo} alt="logo" className='logo'/>
            <img src={logo} alt="logo" className='logo'/>
            <img src={logo} alt="logo" className='logo'/>
            <img src={logo} alt="logo" className='logo'/>
            <img src={logo} alt="logo" className='logo'/>
            <img src={logo} alt="logo" className='logo'/>
            <img src={logo} alt="logo" className='logo'/>
            <img src={logo} alt="logo" className='logo'/>
            <img src={logo} alt="logo" className='logo'/>
            </div>
          </div>
      </section>
    )
  }

  export default LandingPage;