import '../Header.css'

import logo from '../Logo.png'
import AnchorLink from "react-anchor-link-smooth-scroll";

function CategoryHeader(){
  

  return (
    <div className="header" id="header">
        <img src={logo} className="logo" alt="logo"/>
        <nav>
        <a href="./#LandingPage" className="link">Home</a>
        <a href="./#about" className="link">About Us</a>
        <a href="./#collection" className="link">Collections</a>
        <a href="./#Contact" className="link">Contact</a>
        </nav>
    </div>
  )
}



export default CategoryHeader;