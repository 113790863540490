import './women.css'
import sweater from './assessts-women/sweater.jpg'
import sweatshirt from './assessts-women/sweatshirt.jpg'
import formalpants from './assessts-women/formal pants.jpg'
import jeans from './assessts-women/jeans.jpg'
import kurta from './assessts-women/kurta.jpg'
import scarf from './assessts-women/scarf.jpg'
import lingerie from './assessts-women/lingerie.jpg'
import tops from './assessts-women/top.jpg'
function Women(){
    return(
        <div id='Womenswear'>
            <h2 id='heading'>Women's Collection</h2>
            <div id='men-cards'>
            <div >
                <img src={sweater}   alt="" className='mens-img'/>
                <p>Sweaters</p>
            </div>
            <div   >
                <img src={sweatshirt}   alt="" className='mens-img'/>
                <p>Sweatshirts</p>
            </div>
            <div   >
                <img src={jeans}   alt="" className='mens-img'/>
                <p>Jeans and Jeggings</p>
            </div>
            <div   >
                <img src={formalpants}   alt="" className='mens-img'/>
                <p>=Formal Pants</p>
            </div>
            <div   >
                <img src={tops}   alt="" className='mens-img'/>
                <p>Tops</p>
            </div>
            <div   >
                <img src={kurta}   alt="" className='mens-img'/>
                <p>Kurta and Leggings</p>
            </div>
            <div   >
                <img src={scarf}   alt="" className='mens-img'/>
                <p>Scarf and Caps</p>
            </div>
            <div   >
                <img src={lingerie}   alt="" className='mens-img'/>
                <p>Lingerie</p>
            </div>
            </div>
        </div>
    )
}

export default Women