import './Footer.css'
import logo from './Logo.png'

function Footer(){
    return(
        <section id='Footer'>
            <hr />
            <div id='footer--content'>
                <div id='footer--content--one' >
                        <img src={logo} alt="logo" className='footer--logo'/>
                        <div id='copyright'><p><span>&copy;</span>Selections 2023</p>
                        <p>All rights reserved</p></div>
                </div>
                <div id='footer--content--two' className='inner--content'>
                        <a href="#LandingPage" className='link'>Home</a>
                        <a href="#collection" className='link'>Collections</a>
                        <a href="#about" className='link'>About us</a>
                </div>
                <div id='footer--content--three' className='inner--content'>
                        <a href="#brands" className='link'>Brands</a>
                        <a href="#Gallery" className='link'>Gallery</a>
                        <a href="#Testimonials" className='link'>Testimony</a>
                </div>
                <div id='footer--content--four' className='inner--content'>
                        <a href="#Contact" className='link'>Contact Us</a>
                        <a href="https://instagram.com/selections_shillong?igshid=YzAwZjE1ZTI0Zg%3D%3D&utm_source=qr" className='link'>Instagram</a>
                </div>
            </div>
        </section>
    )
}

export default Footer