import './kids.css'
import tshirt from './assessts-kids/T shirt.jpg'
import shirt from './assessts-kids/shirt.jpg'
import sweater from './assessts-kids/sweater.jpg'
import sweatshirt from './assessts-kids/sweatshirt.jpg'
import blazer from './assessts-kids/blazer.jpg'
import overcoat from './assessts-kids/overcoat.jpg'
import suit from './assessts-kids/full suit.jpg'
import jacket from './assessts-kids/jackets.jpg'
import jeans from './assessts-kids/Jeans.jpg'
import vest from './assessts-kids/vest.jpg'
import socks from './assessts-kids/socks.jpg'
import babysuit from './assessts-kids/babysuit.jpg'

function Kids(){
    return(
        <div id='Kidswear'>
            <h2 id='heading'>Kid's Collection</h2>
            <div id='men-cards'>
            <div >
                <img src={tshirt}   alt="" className='mens-img'/>
                <p>T-Shirts</p>
            </div>
            <div   >
                <img src={shirt}   alt="" className='mens-img'/>
                <p>Shirts</p>
            </div>
            <div   >
                <img src={sweater}   alt="" className='mens-img'/>
                <p>Sweaters</p>
            </div>
            <div   >
                <img src={sweatshirt}   alt="" className='mens-img'/>
                <p>Sweatshirts</p>
            </div>
            <div   >
                <img src={blazer}   alt="" className='mens-img'/>
                <p>Blazers</p>
            </div>
            <div   >
                <img src={suit}   alt="" className='mens-img'/>
                <p>Suits</p>
            </div>
            <div   >
                <img src={overcoat}   alt="" className='mens-img'/>
                <p>Overcoats</p>
            </div>
            <div   >
                <img src={jacket}   alt="" className='mens-img'/>
                <p>Jackets</p>
            </div>
            <div   >
                <img src={jeans}   alt="" className='mens-img'/>
                <p>Jeans and Formal Pants</p>
            </div>
            <div   >
                <img src={vest}   alt="" className='mens-img'/>
                <p>Vests and briefs</p>
            </div>
            <div   >
                <img src={socks}   alt="" className='mens-img'/>
                <p>Socks</p>
            </div>
            <div   >
                <img src={babysuit}   alt="" className='mens-img'/>
                <p>Baby suits</p>
            </div>
            </div>
        </div>
    )
}

export default Kids