import './App.css';

//components
import Header from './components/Header.js'
import LandingPage from './components/LandingPage';
import About from './components/About';
import Collection from './components/Collection';
import Brands from './components/Brands'
import Team from './components/Team'
import Gallery from './components/Gallery'
import Testimonials from './components/Testimonials'
import Contact from './components/Contact'
import Footer from './components/Footer'
import Mens from './components/category-component/mens'
import Women from './components/category-component/women.js'
import Kids from './components/category-component/kids.js'
import FooterCategory from './components/category-component/category-footer.js'
import CategoryHeader from './components/category-component/category-header.js'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
//routing


function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path='/'>
              <Header />
              <LandingPage />
              <About />
              <Collection />
              <Brands />
              <Team />
              <Gallery />
              <Testimonials/>
              <Contact />
              <Footer /> 
          </Route>
            <Route exact path='/men'>
                <CategoryHeader />
                <Mens />
                <FooterCategory />
            </Route>
            <Route exact path='/women'>
            <CategoryHeader />
                <Women />
                <FooterCategory />
            </Route>
            <Route exact path='/kids'>
            <CategoryHeader />
                <Kids />
                <FooterCategory />
            </Route>
          </Switch> 
      </div>
    </Router>
  );
}

export default App;
