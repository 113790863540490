import './Brands.css'
import blueman from './bg-removed/blueman.png'
import c from './bg-removed/c.png'
import cousag from './bg-removed/cousag.png'
import crimsoune from './bg-removed/crimsoune.png'
import elxsi from './bg-removed/elxsi.png'
import juelle from './bg-removed/juelle.png'
import killer from './bg-removed/killer.png'
import lureurban from './bg-removed/lureurban.png'
import newcastle from './bg-removed/newcastle.png'
import ninomentalli from './bg-removed/ninomantelli.png'
import octave from './bg-removed/octave.png'
import perallie from './bg-removed/perrialle.png'
import silkina from './bg-removed/silkina.png'
import superkids from './bg-removed/superkids.png'
import timeoption from './bg-removed/timeoption.png'

function Brands(){
    return(
        <section id='brands'>
            <div id='heading'>Brands We Deal With</div> 
            <div id='cards'>
            <div className='card'>
                <img src={octave} alt="" />
            </div>
            <div className='card'>
                <img src={elxsi} alt="" />
            </div>
            <div className='card'>
                <img src={perallie} alt="" />
            </div>
            <div className='card'>
                <img src={ninomentalli} alt="" />
            </div>
            <div className='card'>
                <img src={silkina} alt="" />
            </div>
            <div className='card'>
                <img src={blueman} alt="" />
            </div>
            <div className='card'>
                <img src={juelle} alt="" />
            </div>
            <div className='card'>
                <img src={superkids} alt="" />
            </div>
            <div className='card'>
                <img src={crimsoune} alt="" />
            </div>
            <div className='card'>
                <img src={newcastle} alt="" />
            </div>
            <div className='card'>
                <img src={timeoption} alt="" />
            </div>
            <div className='card'>
                <img src={lureurban} alt="" />
            </div>
            <div className='card'>
                <img src={cousag} alt="" />
            </div>
            <div className='card'>
                <img src={killer} alt="" />
            </div>
            <div className='card'>
                <img src={c} alt="" />
            </div>
            </div>
        </section>
    );
}

export default Brands