import './Header.css'
import logo from './Logo.png'
import AnchorLink from "react-anchor-link-smooth-scroll";

function Header(){
  

  return (
    <div className="header" id="header">
        <img src={logo} className="logo" alt="logo"/>
        <nav>
        <AnchorLink href="#LandingPage" className="link">Home</AnchorLink>
        <AnchorLink href="#about" className="link">About Us</AnchorLink>
        <AnchorLink href="#collection" className="link">Collections</AnchorLink>
        <AnchorLink href="#Contact" className="link">Contact</AnchorLink>
        </nav>
         {/* <a href="javascript:void(0);" class="icon" onclick={myFunction()}>
            <HiMenu/>
        </a>   */}
    </div>
  )
}

function myFunction() {
  var x = document.getElementById("header");
  if (x.className === "header") {
    x.className = "responsive";
  } else {
    x.className = "header";
  }
}


export default Header;
