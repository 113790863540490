import './mens.css'
import shirts from './assessts-men/Shirts.jpg'
import TShirts from './assessts-men/TShirt.jpg'
import Sweater from './assessts-men/Sweater.jpg'
import Sweatshirt from './assessts-men/Sweatshirt.jpg'
import blazer from './assessts-men/blazer.jpg'
import overcoat from './assessts-men/overcoat.jpg'
import Suit from './assessts-men/Suit.jpg'
import Jacket from './assessts-men/Jackets.jpg'
import formalpants from './assessts-men/Formal Pants.jpg'
import Jeans from './assessts-men/Jeans.jpg'
import Vest from './assessts-men/Vest.jpg'
import Brief from './assessts-men/Briefs.jpg'
import Tie from './assessts-men/Tie.jpg'
import Handkerchief from './assessts-men/Handkerchief.jpg'
import Socks from './assessts-men/Socks.jpg'
import Footer from '../Footer'

function Mens(){
    return(
        <div id='Menswear'>
            <h2 id='heading'>Men's Collection</h2>
            <div id='men-cards'>
            <div >
                <img src={shirts}   alt="" className='mens-img'/>
                <p>Shirts</p>
            </div>
            <div   >
                <img src={TShirts}   alt="" className='mens-img'/>
                <p>T- Shirts</p>
            </div>
            <div   >
                <img src={Sweater}   alt="" className='mens-img'/>
                <p>Sweaters</p>
            </div>
            <div   >
                <img src={Sweatshirt}   alt="" className='mens-img'/>
                <p>Sweatshirts</p>
            </div>
            <div   >
                <img src={blazer}   alt="" className='mens-img'/>
                <p>Blazers</p>
            </div>
            <div   >
                <img src={Suit}   alt="" className='mens-img'/>
                <p>Suits</p>
            </div>
            <div   >
                <img src={overcoat}   alt="" className='mens-img'/>
                <p>Overcoats</p>
            </div>
            <div   >
                <img src={Jacket}   alt="" className='mens-img'/>
                <p>Jackets</p>
            </div>
            <div   >
                <img src={formalpants}   alt="" className='mens-img'/>
                <p>Formal Pants</p>
            </div>
            <div   >
                <img src={Jeans}   alt="" className='mens-img'/>
                <p>Jeans</p>
            </div>
            <div   >
                <img src={Vest}   alt="" className='mens-img'/>
                <p>Vests</p>
            </div>
            <div   >
                <img src={Brief}   alt="" className='mens-img'/>
                <p>Briefs</p>
            </div>
            <div   >
                <img src={Handkerchief}   alt="" className='mens-img'/>
                <p>Handkerchiefs</p>
            </div>
            <div   >
                <img src={Socks}   alt="" className='mens-img'/>
                <p>Socks</p>
            </div>
            <div   >
                <img src={Tie}   alt="" className='mens-img'/>
                <p>Tie</p>
            </div>
            </div>
        </div>
    )
}

export default Mens